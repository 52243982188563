import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrors } from '@/interfaces/common';
import { axiosData } from '@/axios';

import type { AxiosError, AxiosResponse } from 'axios';
 

const modulePrefix = '/certificados';
 
export interface CertificadoData {
  Nombre:  string;
  IdCertificado:  number;
}

export interface CertificadoResponse {
  data: CertificadoData[];
}

export interface CertificadoProps {
  order?: string;
  activo?: number;
}

export const getCertificados  = createAsyncThunk(
  `${modulePrefix}/getCertificados`,
  async ({ order, activo }: CertificadoProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(`/certificados`, {
           params: { order:"Nombre", activo, limit:200 },
      });
      const result: CertificadoResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

 